.task-assign-to {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;
  width: 150px;

  .input {
    cursor: pointer;
  }

  .participant-name {
    color: #404040;
  }

  & a {
    padding: 6px 12px;
    font-size: 12px;
    transition: 0.2s;

    &:hover {
      color: #5a5a5a;
      background: #f0f0f0;
      border-radius: 2px;
    }
  }
}

.status {
  height: 10px;
  width: 10px;
  cursor: pointer;

  &.default {
    background: #d3d3d3;
    border: none;
  }

  &.complete {
    border: none;
    background: #6bc950;
  }

  &.in-review {
    border: none;
    background: #ff7800;
  }

  &.in-progress {
    border: none;
    background: #0231e8;
  }

  &.to-do {
    border: none;
    background: #d3d3d3;
  }
}
