.container {
  position: relative;

  .placeholder {
    position: absolute;
    padding: 12px;
    top: 0;
    left: 0;
    pointer-events: none;
    color: gray;
  }

  .wrapper {
    word-break: break-word;
    line-height: 1.5;

    img {
      width: 100%;
      height: auto;
    }

    pre {
      display: inline-block;
      vertical-align: top;
      font-family: monospace;
      font-size: 1em;
      margin: 0;
      padding: 0.5em;
      white-space: pre-wrap;
      background-color: #23241f;
      color: #f8f8f2;
      border-radius: 2px;
      border: 1px solid #ddd;
      overflow-x: auto;
    }

    p {
      margin: 0;
    }
  }
}

.rich-input {
  cursor: pointer;
  outline: none;
}
