.comment-container {
  background-color: #fff;
  display: flex;
  gap: 8px;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
  margin-bottom: 16px;

  .comment-body {
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;

    &:hover {
      background-color: #f7f8f9;
    }
  }

  .comment-active {
    border: 1px solid #e9ebf0;
    border-radius: 2px;
    background-color: #bfbfbf;
    width: 100%;
    padding: 8px 16px 16px 16px;
    -webkit-transition: background-color 300ms linear;
    -ms-transition: background-color 300ms linear;
    transition: background-color 300ms linear;

    &:hover {
      background-color: #f7f8f9;
    }
  }

  .comment-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    .user-name {
      font-size: 14px;
      font-weight: 600;
    }

    .message-date {
      margin-left: 12px;
      font-size: 12px;
    }
  }
}
