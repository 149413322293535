.tabs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 16px 0 24px 0;

  .details-tab,
  .activity-tab {
    button {
      transition: 0.2s;
      font-weight: 500;
      line-height: 1;
      font-size: 16px;
      color: #a5a9b0;
      border: none;
      background: none;
      letter-spacing: 0.8px;
      cursor: pointer;
      text-transform: uppercase;
    }
  }
}
