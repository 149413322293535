.task-details {
  width: 100% !important;
  top: 16px !important;
  padding-bottom: 0px !important;

  .task-details .ant-modal-content {
    padding: 0 !important;
  }

  .task-details .ant-modal-header {
    padding: 20px 24px !important;
  }
}

.model-container {
  height: 100%;
  width: 100%;
}

.batch-heading {
  display: flex;
  align-items: center;
  border: 1px solid #e9ebf0;
  width: max-content;
  border-radius: 3px;

  a {
    color: #7c828d;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 400;
  }
}
