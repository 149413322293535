.new-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2px 8px;

  &:hover {
    background-color: #f7f8f9;
  }
}
