.container {
  position: relative;

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    color: gray;
  }

  .wrapper {
    padding: 16px;

    .rich-input {
      cursor: pointer;
      outline: none;
      font-size: 14px;
      color: #232323;
      line-height: 1.5;
      word-break: break-all;

      img {
        width: 100%;
        height: auto;
      }

      pre {
        display: inline-block;
        vertical-align: top;
        font-family: monospace;
        font-size: 1em;
        margin: 0;
        padding: 0.5em;
        white-space: pre-wrap;
        background-color: #23241f;
        color: #f8f8f2;
        border-radius: 2px;
        border: 1px solid #ddd;
        overflow: auto;
      }

      p {
        margin: 0;
        word-break: break-all;
      }
    }
  }
}
