.checklist {
  &:not(:last-child) {
    margin-bottom: 24px;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;

    .title {
      font-size: 16px;
      font-weight: 500;
    }

    .more-icon {
      display: none;
      cursor: pointer;
      padding: 4px 4px 0px 4px;

      &:hover {
        border-radius: 4px;
        background-color: #eef0f2;
      }
    }

    &:hover {
      .more-icon {
        display: block;
      }
    }
  }

  .checklist-items {
    // padding: 8px 0;
    // border: 1px solid #e2e2e2;
    // border-radius: 6px;
  }
}
