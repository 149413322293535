.container {
  background: #fff;
  border: 1px solid #e9ebf0;
  border-radius: 2px;
}

.container .ql-toolbar.ql-snow {
  border: none !important;
  background: #f7f8f9;
  border-radius: 8px;
}

.container .ql-container {
  font-size: 14px;
}

.container .ql-container.ql-snow {
  border: none !important;
  max-height: 100px;
  overflow: auto;
}

.container .ql-snow .ql-editor pre.ql-syntax {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-family: monospace;
  font-size: 1em;
  margin: 0;
  padding: 0.5em;
  white-space: pre-wrap;
  background-color: #eee;
  color: #212529;
  border-radius: 2px;
  border: 1px solid #ddd;
  overflow-x: auto;
}

.container .editor-footer {
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}

.container .hide-edit-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
}

.container .hide-edit-footer .placeholder {
  color: gray;
  margin-left: 8px;
}

.container .editor-footer .send-btn {
  background: transparent;
  border: none;
}

.container .ql-mention-list-container {
  width: 270px;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
  z-index: 9001;
  overflow: auto;
}

.container .ql-mention-loading {
  line-height: 34px;
  padding: 0 16px;
  vertical-align: middle;
  font-size: 14px;
}

.container .ql-mention-list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.container .ql-mention-list-item {
  cursor: pointer;
  line-height: 44px;
  font-size: 16px;
  padding: 0 20px;
  vertical-align: middle;
}

.container .ql-mention-list-item.disabled {
  cursor: auto;
}

.container .ql-mention-list-item.selected {
  background-color: #f2f2f5;
  text-decoration: none;
}

.container .mention {
  border-radius: 2px;
  background-color: #fff0af;
  padding: 2px;
  margin-right: 4px;
  user-select: all;
  font-weight: 600;
}

.container .mention span {
  margin: 0 2px;
}