.HeaderLayout {
  .HeaderLayout__sider {
    border-right: 1px solid #e8e8e8;

    @media only print {
      display: none;
    }
  }

  .HeaderLayout__logo {
    height: 32px;
    margin: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .SiteLayout {
    .SiteLayout__header {
      background: #fff;
      border-bottom: 1px solid #e8e8e8;

      @media only print {
        display: none;
      }

      .trigger {
        padding: 0 24px;
        font-size: 18px;
        line-height: 64px;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }

      .header-notifications {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        height: 100%;
      }
    }

    .SiteLayout__background {
      background-color: #edeeee;
    }
  }
}
