.task-details-container {
  height: 100%;
  overflow-y: auto;

  .field {
    margin-bottom: 16px;
  }

  .task-status {
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 576px) {
      align-items: center;
    }

    .task-status-heading {
      display: flex;
      flex-direction: column;
      gap: 12px;

      @media screen and (min-width: 576px) {
        flex-direction: row;
        align-items: center;
        gap: 24px;
      }
    }

    .admin-status-heading {
      display: flex;
      align-items: center;
      gap: 0;
    }
  }
}
