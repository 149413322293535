.comments-list {
  .message-content {
    font-size: 14px;
    word-break: break-word;
    line-height: 1.3;

    img {
      width: 100%;
      height: auto;
    }

    pre {
      display: inline-block;
      // width: 100%;
      vertical-align: top;
      font-family: monospace;
      font-size: 1em;
      margin: 0;
      padding: 0.5em;
      white-space: pre-wrap;
      background-color: #23241f;
      color: #f8f8f2;
      border-radius: 2px;
      border: 1px solid #ddd;
      overflow-x: auto;
    }

    p {
      margin: 0;
    }
  }
}
