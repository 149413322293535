.avatar {
  background: #bcbcbc;
  color: white;
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:not(:first-child) {
    margin-inline-start: -5px;
  }
}

.content {
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
}
