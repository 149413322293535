.status-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 8px;

  & a {
    padding: 4px 10px;
    font-size: 12px;
    border-radius: 4px;

    &.completed {
      color: #6bc950;
      transition: 0.2s;

      &:hover {
        background: #6bc950;
        color: #fff;
      }
    }

    &.in-review {
      color: #ff7800;
      transition: 0.2s;

      &:hover {
        background: #ff7800;
        color: #fff;
      }
    }

    &.in-progress {
      color: #0231e8;
      transition: 0.2s;

      &:hover {
        background: #0231e8;
        color: #fff;
      }
    }

    &.to-do {
      color: #a0a4aa;
      transition: 0.2s;

      &:hover {
        background: #a0a4aa;
        color: #fff;
      }
    }
  }
}

.status {
  height: 12px;
  width: 12px;
  cursor: pointer;
  border-radius: 2px;

  &.default {
    background: #d3d3d3;
    border: none;
  }

  &.completed {
    border: none;
    background: #6bc950;
  }

  &.in-review {
    border: none;
    background: #ff7800;
  }

  &.in-progress {
    border: none;
    background: #0231e8;
  }

  &.to-do {
    border: none;
    background: #d3d3d3;
  }
}

.task-details {
  display: flex;
  align-items: center;
  gap: 2px;

  .task-details-status {
    border-radius: 2px 0px 0px 2px;
    padding: 4px 12px;
    cursor: pointer;
    width: max-content;

    .task-status-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      white-space: nowrap;
      color: #fff;
    }

    .task-status-to-do {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      white-space: nowrap;
      color: #343434;
    }

    &.default {
      background: #d3d3d3;
      border: none;
    }

    &.completed {
      border: none;
      background: #6bc950;
    }

    &.in-review {
      border: none;
      background: #ff7800;
    }

    &.in-progress {
      border: none;
      background: #0231e8;
    }

    &.to-do {
      border: none;
      background: #d3d3d3;
    }
  }

  .task-details-next {
    border-radius: 0px 2px 2px 0px;
    padding: 4px 8px;
    cursor: pointer;
    width: max-content;

    .task-status-title {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      white-space: nowrap;
      color: #fff;
    }

    .task-status-to-do {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;
      white-space: nowrap;
      color: #343434;
    }

    &.default {
      background: #d3d3d3;
      border: none;
    }

    &.completed {
      border: none;
      background: #6bc950;
    }

    &.in-review {
      border: none;
      background: #ff7800;
    }

    &.in-progress {
      border: none;
      background: #0231e8;
    }

    &.to-do {
      border: none;
      background: #d3d3d3;
    }
  }
}
