.task-assignments {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .input {
    cursor: pointer;
  }

  .task-status {
    height: 10px;
    width: 10px;

    &.completed {
      background: #6bc950;
    }

    &.in-review {
      background: #ff7800;
    }

    &.in-progress {
      background: #0231e8;
    }

    &.to-do {
      background: #d3d3d3;
    }

    &.default {
      background: #d3d3d3;
    }
  }
}
