.checklist-item {
  padding: 2px 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &:hover {
    background-color: #f7f8f9;

    .actions {
      display: block;
    }
  }

  .checkbox {
    &.completed {
      color: #999;
      text-decoration: line-through;
    }
  }

  .actions {
    display: none;
  }
}
