.action {
  padding: 4px 8px;

  &:hover {
    background-color: #f7f8f9;
  }
}

.more {
  cursor: pointer;
  padding: 4px 4px 0px 4px;

  &:hover {
    background-color: #eef0f2;
    border-radius: 4px;
  }
}
